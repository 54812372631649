import {
    ThemeProvider as MuiThemeProvider,
    createTheme,
} from "@mui/material/styles";
import classNames from "classnames";
import React, { ReactElement, useContext, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ThemeContext, ThemeProvider } from "styled-components";

import evaluteVisibilityFromDependencies from "utils/evaluteVisibilityFromDependencies";
import generateValidationRules from "utils/generateValidationRules";

import {
    ErrorIcon,
    ErrorText,
    FieldWrapper,
    StyledTextArea,
} from "./TextArea.styled";
import TextAreaProps from "./TextAreaProps";

const TextArea = ({
    id,
    identifier,
    label,
    placeHolder,
    focused,
    disabled,
    defaultValue,
    predefinedValue,
    displayOption = "Full",
    autoComplete,
    description,
    className,
    elementName,
    minRows = 2,
    validators,
    dependencies,
    control,
    size = "medium",
    setValue,
}: TextAreaProps): ReactElement => {
    const formContext = useFormContext();
    const unregister = formContext?.unregister || undefined;
    defaultValue = defaultValue || predefinedValue;

    const classes = classNames(
        className,
        validators?.map((validator) => {
            return validator?.model?.validationCssClass || "";
        }),
    );

    const rules =
        validators && validators?.length > 0
            ? generateValidationRules(validators)
            : undefined;

    const themeContext = useContext(ThemeContext);
    const visibleFromDependencies =
        dependencies && control
            ? evaluteVisibilityFromDependencies(dependencies, control)
            : true;

    useEffect(() => {
        if (elementName && setValue)
            if (visibleFromDependencies) setValue(elementName, defaultValue);
            else unregister(elementName);
    }, [
        defaultValue,
        elementName,
        unregister,
        setValue,
        visibleFromDependencies,
    ]);

    return (
        <MuiThemeProvider theme={createTheme()}>
            <ThemeProvider
                theme={{
                    ...themeContext,
                    displayOption: displayOption,
                    size,
                }}
            >
                {visibleFromDependencies && (
                    <Controller
                        name={elementName || ""}
                        control={control}
                        defaultValue={defaultValue || ""}
                        rules={rules}
                        disabled={disabled}
                        render={({ field, fieldState: { error } }) => (
                            <FieldWrapper className={className}>
                                {error && (
                                    <ErrorText>
                                        <span>{error.message}</span>
                                    </ErrorText>
                                )}
                                <StyledTextArea
                                    id={id || identifier}
                                    className={classes}
                                    label={label}
                                    placeholder={placeHolder}
                                    focused={focused}
                                    error={!!error}
                                    disabled={disabled}
                                    helperText={description}
                                    autoComplete={autoComplete}
                                    InputProps={{
                                        endAdornment:
                                            (
                                                <>
                                                    {error && (
                                                        <ErrorIcon icon="error28" />
                                                    )}
                                                </>
                                            ) || null,
                                    }}
                                    minRows={minRows}
                                    variant="outlined"
                                    multiline
                                    {...field}
                                />
                            </FieldWrapper>
                        )}
                    />
                )}
            </ThemeProvider>
        </MuiThemeProvider>
    );
};

export default React.memo(TextArea);
