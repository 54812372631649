import { FormHelperText, TextField as MuiTextField } from "@mui/material";
import { styled } from "styled-components";
import theme from "styled-theming";

import Icon from "components/Icon";
import {
    styleBodyL,
    styleBodyM,
    styleBodyS,
    styleBodySSpaced,
    styleLabelM,
} from "style/components/Typography";
import { MQ } from "style/mediaQueries";

const labelFocus = theme("theme", {
    undefined: "--text-on-neutral-secondary",
    lightgray: "--text-on-neutral-secondary",
    blue: "--text-on-blue-secondary",
    cyan: "--text-on-cyan-secondary",
    black: "--text-on-blue-secondary",
});

const textPlaceholder = theme("theme", {
    undefined: "--text-on-neutral-disabled",
    lightgray: "--text-on-neutral-disabled",
    blue: "--text-on-blue-disabled",
    cyan: "--text-on-cyan-disabled",
    black: "--text-on-blue-disabled",
});

const textDisabled = theme("theme", {
    undefined: "--text-on-neutral-disabled",
    lightgray: "--text-on-neutral-disabled",
    blue: "--text-on-blue-disabled",
    cyan: "--text-on-cyan-disabled",
    black: "--text-on-blue-disabled",
});

const textError = theme("theme", {
    undefined: "--text-on-neutral-inverted",
    lightgray: "--text-on-neutral-inverted",
    blue: "--text-on-coral-primary",
    cyan: "--text-on-neutral-inverted",
    black: "--text-on-coral-primary",
});

const textColor = theme("theme", {
    undefined: "--text-on-neutral-primary",
    lightgray: "--text-on-neutral-primary",
    blue: "--text-on-blue-primary",
    cyan: "--text-on-cyan-primary",
    black: "--text-on-blue-primary",
});

const borderColor = theme("theme", {
    undefined: "--field-on-neutral-default-border",
    lightgray: "--field-on-neutral-default-border",
    blue: "--field-on-blue-default-border",
    cyan: "--field-on-cyan-default-border",
    black: "--field-on-blue-default-border",
});

const borderHover = theme("theme", {
    undefined: "--field-on-neutral-hover-border",
    lightgray: "--field-on-neutral-hover-border",
    blue: "--field-on-blue-hover-border",
    cyan: "--field-on-cyan-hover-border",
    black: "--field-on-blue-hover-border",
});

const borderError = theme("theme", {
    undefined: "--field-on-neutral-error-border",
    lightgray: "--field-on-neutral-error-border",
    blue: "--field-on-blue-error-border",
    cyan: "--field-on-cyan-error-border",
    black: "--field-on-blue-error-border",
});

const borderDisabled = theme("theme", {
    undefined: "--field-on-neutral-disabled-border",
    lightgray: "--field-on-neutral-disabled-border",
    blue: "--field-on-blue-disabled-border",
    cyan: "--field-on-cyan-disabled-border",
    black: "--field-on-blue-disabled-border",
});

export const FieldWrapper = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 0;
    grid-column: col-start 1 / span 2;
    width: 100%;

    ${MQ.FROM_M} {
        grid-column: ${theme("displayOption", {
            Full: "auto / span 6",
            Half: "auto / span 3",
            OneThird: "auto / span 2",
            TwoThirds: "auto / span 4",
        })};
    }
`;

export const StyledTextArea = styled(MuiTextField)`
    width: 100%;

    && {
        .MuiInputLabel {
            &-root {
                ${theme("size", {
                    small: styleBodyM,
                    medium: styleBodyL,
                })};
                color: var(${textColor});
                padding: 0 0 0 0.875rem;
                transform: ${theme("size", {
                    small: "translate(0, 0.875rem)",
                    medium: "translate(0, 1.3125rem)",
                })};

                ${MQ.FROM_XL} {
                    padding: ${theme("size", {
                        small: "0 0 0 0.875rem",
                        medium: "0 0 0 1.3125rem",
                    })};
                    transform: ${theme("size", {
                        small: "translate(0, 1.3125rem)",
                        medium: "translate(0, 1.75rem)",
                    })};
                }

                &.Mui-error {
                    padding-right: 1.3125rem;

                    ${MQ.FROM_XL} {
                        padding-right: 1.3125rem;
                    }
                }

                &.Mui-focused {
                    ${theme("size", {
                        small: styleBodySSpaced,
                        medium: styleBodyM,
                    })};
                    color: var(${labelFocus});
                    transform: ${theme("size", {
                        small: "translate(0, 0)",
                        medium: "translate(0, 0.4375rem)",
                    })};

                    ${MQ.FROM_XL} {
                        transform: ${theme("size", {
                            small: "translate(0, 0.4375rem)",
                            medium: "translate(0, 0.875rem)",
                        })};
                    }
                }

                &.Mui-disabled {
                    color: var(${textDisabled});
                }
            }

            &-shrink {
                ${theme("size", {
                    small: styleBodySSpaced,
                    medium: styleBodyM,
                })};
                color: var(${labelFocus});
                transform: ${theme("size", {
                    small: "translate(0, 0)",
                    medium: "translate(0, 0.4375rem)",
                })};

                ${MQ.FROM_XL} {
                    transform: ${theme("size", {
                        small: "translate(0, 0.4375rem)",
                        medium: "translate(0, 0.875rem)",
                    })};
                }

                /* prettier-ignore */
                &:has(+ .MuiOutlinedInput-root
                        .MuiInputBase-input:-webkit-autofill) {
                    color: var(--color-black);
                }
            }
        }

        .MuiInputBase {
            &-input {
                ${theme("size", {
                    small: styleBodyM,
                    medium: styleBodyL,
                })};
                color: var(${textColor});
                height: ${theme("size", {
                    small: "2.1875rem !important",
                    medium: "2.625rem !important",
                })};
                padding: ${theme("size", {
                    small: "1.75rem 0.875rem 0.875rem",
                    medium: "2.1875rem 0.875rem 0.875rem",
                })};

                ${MQ.FROM_XL} {
                    height: ${theme("size", {
                        small: "2.625rem !important",
                        medium: "3.5rem !important",
                    })};
                    padding: ${theme("size", {
                        small: "2.1875rem 0.875rem 0.875rem",
                        medium: "2.625rem 1.3125rem 1.3125rem",
                    })};
                }

                &.Mui-disabled {
                    -webkit-text-fill-color: var(${textDisabled});
                    color: var(${textDisabled});
                }

                &::placeholder {
                    color: var(${textPlaceholder});
                    opacity: 1;
                }
            }

            &-root {
                padding: 0;

                .MuiOutlinedInput {
                    &-notchedOutline {
                        border-color: var(${borderColor});
                        transition: border-color 0.2s ease-in-out;
                        top: 0;

                        legend {
                            height: 0;
                            overflow: hidden;
                            width: 0;
                        }
                    }
                }

                &:hover {
                    &:not(.Mui-disabled, .Mui-error) {
                        .MuiOutlinedInput {
                            &-notchedOutline {
                                border-color: var(${borderHover});
                            }
                        }
                    }
                }

                &.Mui-error.Mui-focused,
                &.Mui-error {
                    .MuiOutlinedInput {
                        &-notchedOutline {
                            border-top-left-radius: 0;
                            border-color: var(${borderError});
                        }
                    }

                    .MuiInputBase-input {
                        padding-right: 3.5rem;

                        ${MQ.FROM_XL} {
                            padding-right: 3.9375rem;
                        }
                    }
                }

                &.Mui-focused {
                    .MuiOutlinedInput {
                        &-notchedOutline {
                            border-color: var(${borderHover});
                            border-width: 1px;
                            box-shadow: 0 0 0 0.4375rem var(--field-focus);
                        }
                    }
                }

                &.Mui-disabled {
                    .MuiOutlinedInput {
                        &-notchedOutline {
                            border-color: var(${borderDisabled});
                        }
                    }
                }
            }
        }

        .MuiFormHelperText {
            &-root {
                ${theme("size", {
                    small: styleBodyS,
                    medium: styleBodyM,
                })};
                color: var(${labelFocus});
                margin: 0.4375rem 0 0;

                &.Mui-disabled {
                    color: var(${textDisabled});
                }
            }
        }
    }
`;

export const ErrorIcon = styled(Icon)`
    color: var(${borderError});
    position: absolute;
    right: 0.875rem;
    top: 0.875rem;

    ${MQ.FROM_XL} {
        top: 1.3125rem;
    }
`;

export const ErrorText = styled(FormHelperText)`
    && {
        ${styleLabelM};
        margin: 0 auto 0 0;
        z-index: 2;

        span {
            background-color: var(${borderError});
            border-radius: 0.25rem 0.25rem 0 0;
            color: var(${textError});
            display: block;
            margin-right: 2.625rem;
            padding: 0 0.4375rem;
        }
    }
`;
